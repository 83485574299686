.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.link-block-2 {
  align-self: center;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.image-6 {
  width: 30px;
  height: 30px;
}

.footer-menu-list {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  display: flex;
}

.footer-menu-list.socials {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
