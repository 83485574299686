.onboardingFooter {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  color: #1e88e5;
  width: 100%;
  padding: 10px;
  margin-left: -32px;
  right: 0;
}

.continueOnboardingBtnDiv {
  @media (min-width: 800px) {
    float: right;
    margin-right: 30px;
  }
}

.groupedOverviewItems {
  margin-left: 50px;
  border: 1px solid #dddddd;
  padding: 5px 20px 5px 5px;
  margin-right: 100px;
  gap: 12px;
  border-radius: 11px;
}

.longJoiner {
  margin-left: 15px;
  margin-top: -1px;
  width: 1px;
  margin-bottom: 10px;
  border-width: 1px;
  border-left-style: initial;
  border-color: rgb(0, 174, 155);
  position: absolute;
}
